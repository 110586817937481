import React, { useEffect, useReducer } from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Button, Select } from "antd";
import moment from "moment";

import EmptyData from "../../components/EmptyData";
import { ContentBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import { removeEmptyKeys, removePreloader } from "../../helpers/index";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import Masonry from "../../components/Masonry";
import {
	ALL_ARTICLES,
	ALL_FILTERED_ARTICLES,
	MEDIA_ARTICLES_MAX_UPDATE,
} from "../../queries/queries.graphql";
import seoData from "../../components/SEO/data";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query allArticlesQuery($category: [String!], $pagePath: String!) {
		hasura {
			...AllArticles
			...Categories
			...Cities
			...PageSettings
		}
	}
`;

export default function AllArticlesPage({ data }) {
	const { Option } = Select;
	const limit = 10;
	const filteredLimit = 10;
	const headers = {
		fetchPolicy: "cache-and-network",
		partialRefetch: true,
	};

	const dateNowFormated = +new Date();
	const dateNow = moment().format();

	const { theme } = useThemeContext();

	function formatData(data) {
		return data.map(item => (
			{
				type: "MediaArticle",
				data: item,
			}
		));
	}

	function init() {
		return {
			articles: formatData(get(data, "hasura.media_articles", [])),
			offset: limit,
			filteredOffset: 0,
			category: undefined,
			city: undefined,
			seo: get(data, "hasura.page_settings[0]", {}),
		};
	}

	function reducer(state, { type, payload }) {
		switch (type) {
			case "articles":
				return {
					...state,
					articles: payload.filter(item => +new Date(get(item, "data.published_at", "")) <= dateNowFormated),
				};
			case "category":
				return {
					...state,
					category: payload,
					filteredOffset: state.category !== payload ? 0 : state.filteredOffset,
				};
			case "city":
				return {
					...state,
					city: payload,
					filteredOffset: state.city !== payload ? 0 : state.filteredOffset,
				};
			case "offset":
				return {
					...state,
					offset: payload,
				};
			case "filteredOffset":
				return {
					...state,
					filteredOffset: payload,
				};
			case "seo":
				return {
					...state,
					seo: payload,
				};
			default:
				return state;
		}
	}

	const [state, dispatch] = useReducer(reducer, {}, init);

	const { articles, category, city, offset, filteredOffset, seo } = state;

	function makeOptions(items) {
		return items.map((item, idx) =>
			<Option
				value={get(item, "slug", "")}
				key={`category-${idx}`}
			>
				{get(item, "title_full", "")}
			</Option>,
		);
	}

	const [
		loadArticles,
		{
			called: allArticlescalled,
			loading: allArticlesLoading,
			error: allArticlesError,
			data: articlesData,
		},
	] = useLazyQuery(
		ALL_ARTICLES,
		{
			variables: {
				category,
				limit,
				dateNow,
			},
			...headers,
		});

	const [
		loadDynamicArticles,
		{
			called: allArticlesDynamicCalled,
			loading: allArticlesDynamicLoading,
			error: allArticlesDynamicError,
			data: articlesDynamicData,
		},
	] = useLazyQuery(
		ALL_ARTICLES,
		{
			variables: {
				category,
				limit,
				dateNow,
			},
			...headers,
		});

	const [
		loadFilteredArticles,
		{
			called: filterdCalled,
			loading: loadingFilteredArticles,
			error: filteredError,
			data: filteredArticlessData,
		},
	] = useLazyQuery(ALL_FILTERED_ARTICLES, headers);

	const maxUpdated = new Date(get(data, "hasura.media_articles_aggregate.aggregate.max.updated_at", new Date()));

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(MEDIA_ARTICLES_MAX_UPDATE, headers);

	function handleFilter(type, payload) {
		if (payload || payload !== "undefined") {
			sessionStorage.setItem(type, payload);
			dispatch(
				{
					type,
					payload,
				},
			);
		} else {
			sessionStorage.removeItem(type);
		}
	}

	useEffect(() => {
		const cityFilterInSessionStorage = sessionStorage.getItem("city");
		const categoryFilterInSessionStorage = sessionStorage.getItem("category");

		if (cityFilterInSessionStorage) {
			handleFilter("city", cityFilterInSessionStorage);
		}

		if (categoryFilterInSessionStorage) {
			handleFilter("category", categoryFilterInSessionStorage);
		}
	}, []);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "media_articles_aggregate.aggregate.max.updated_at", new Date()));
		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadDynamicArticles();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load ArticlesMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (allArticlesDynamicCalled && !allArticlesDynamicError && !allArticlesDynamicLoading) {
			dispatch(
				{
					type: "articles",
					payload: [...formatData(get(articlesDynamicData, "media_articles", []))],
				},
			);
			dispatch(
				{
					type: "offset",
					payload: limit,
				},
			);
		} else if (allArticlesDynamicError) {
			dispatch(
				{
					type: "offset",
					payload: null,
				},
			);
		}

		removePreloader(!allArticlesDynamicLoading || allArticlesDynamicError);
	}, [allArticlesDynamicLoading]);

	useEffect(() => {
		if (allArticlescalled && !allArticlesError && !allArticlesLoading) {
			dispatch(
				{
					type: "articles",
					payload: [...articles, ...formatData(get(articlesData, "media_articles", []))],
				},
			);
			dispatch(
				{
					type: "offset",
					payload: get(articlesData, "media_articles", []).length < limit ? null : offset + limit,
				},
			);
		} else if (allArticlesError) {
			dispatch(
				{
					type: "offset",
					payload: null,
				},
			);
		}

		removePreloader(!allArticlesLoading || allArticlesError);
	}, [allArticlesLoading]);

	useEffect(() => {
		if (filterdCalled && !filteredError && !loadingFilteredArticles) {
			const data = get(filteredArticlessData, "v_media_articles", []);
			dispatch(
				{
					type: "articles",
					payload: !filteredOffset ? formatData(data) : [...articles, ...formatData(data)],
				},
			);
			dispatch(
				{
					type: "filteredOffset",
					payload: data.length < filteredLimit ? null : filteredOffset + filteredLimit,
				},
			);
		}
	}, [loadingFilteredArticles]);

	useEffect(() => {
		const params = {
			category,
			city,
			offset: filteredOffset,
			limit: filteredLimit,
		};

		if (!category || category === "undefined") {
			delete params.category;
		}

		if (!city || city === "undefined") {
			delete params.city;
		}

		if (!!category || !!city) {
			loadFilteredArticles({
				variables: {
					...params,
				},
			});
		}

		if (!category && !city) {
			dispatch(
				{
					type: "articles",
					payload: formatData(get(data, "hasura.media_articles", [])),
				},
			);
			dispatch(
				{
					type: "offset",
					payload: limit,
				},
			);
			dispatch(
				{
					type: "filteredOffset",
					payload: 0,
				},
			);
		}
	}, [category, city]);

	function loadMore() {
		if (!!category || !!city) {
			const variables = {
				category,
				city,
				offset: filteredOffset,
				limit: filteredLimit,
			};

			if (!category) {
				delete variables.category;
			}

			if (!city) {
				delete variables.city;
			}
			loadFilteredArticles({
				variables,
			});
		}

		if (!category && !city) {
			loadArticles({
				variables: {
					offset,
				},
			});
		}
	}

	const url = "articles";
	const categoryOptions = makeOptions(get(data, "hasura.categories", []));
	const cityOptions = makeOptions(get(data, "hasura.cities", []));

	return (
		<Pages url={url} entity={seo || get(seoData, "all_articles", {})}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={"Статьи"} />
				</div>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<div className={"container d-xs-block d-sm-block d-md-flex d-lg-flex justify-content-between"}>
					<h1 itemProp="headline"
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.text,
						})}
					>
						Все статьи
					</h1>
					<div>
						<Select
							placeholder={"Рубрики"}
							showArrow={false}
							style={{ minWidth: 120 }}
							onChange={category => handleFilter("category", category)}
							allowClear
							dropdownMatchSelectWidth={false}
							className={"dropdown d-xs-block d-md-inline-block"}
							value={category === "undefined" ? undefined : category}
						>
							{categoryOptions}
						</Select>
						<span className={"d-xs-none d-md-inline-block"}
							style={{
								paddingRight: 4,
							}}
						>|</span>
						<Select
							placeholder={"Города"}
							showArrow={false}
							style={{ minWidth: 200 }}
							onChange={city => handleFilter("city", city)}
							allowClear
							dropdownMatchSelectWidth={false}
							className={"dropdown d-xs-block d-md-inline-block"}
							value={city === "undefined" ? undefined : city}
						>
							{cityOptions}
						</Select>
					</div>
				</div>
				{articles.length ? <Masonry contentList={articles} /> :
					<EmptyData
						description="Статьи отсутствуют"
					/>}
				<div className="d-flex justify-content-center pb-5">
					<Button
						type={"primary"}
						disabled={
							allArticlesLoading ||
							loadingFilteredArticles ||
							(!category || !city) && !offset ||
							(category || city) && !filteredOffset
						}
						loading={allArticlesLoading}
						onClick={loadMore}
						style={{
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.text,
							lineHeight: theme.lineHeight.text,
							color: theme.color.body,
							backgroundColor: theme.color.text,
						}}
					>
						{showMore}
					</Button>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Все статьи"} />
			</div>
		</Pages>
	);
}

AllArticlesPage.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

AllArticlesPage.defaultProps = {
	data: {},
	pageContext: {},
};
